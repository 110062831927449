/* Ajustar overlay interactivo sobre el video */
.vds-blocker {
  height: 100% !important;
}

.no-video-message {
  color: white;
  font-size: 1.5rem;
  text-align: center;
}

.player-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
}

.unmute-button {
  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(4px);
  padding: 18px;
  border-radius: 50%;
}

.unmute-button-container {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.unmute-button-container.muted {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.unmute-button-container.unmuted {
  opacity: 0;
  pointer-events: none;
  display: hidden;
}

.unmute-icon {
  width: 92px;
  height: 92px;
  fill: white;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.unmute-button-container.muted .unmute-icon {
  opacity: 1;
}

.unmute-button-container.unmuted .unmute-icon {
  opacity: 0;
  transform: scale(1.2);
}
